import React, { useRef, useState, useEffect } from "react";
import { scanVinImage } from '../../Queries/VdrApi/vdrApi'

// Utility function for VIN validation
const Scanner = ({ onVinDetected, onClose }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const overlayCanvasRef = useRef(null);
    const isProcessingRef = useRef(false);
    const capturedImagesRef = useRef([]);
    const isCameraReady = useRef(false); // Track camera readiness

    const [errorMessage, setErrorMessage] = useState("");
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [isTorchAvailable, setIsTorchAvailable] = useState(false); // Track torch availability
    const [isLightOn, setIsLightOn] = useState(false);
    const [track, setTrack] = useState(null); // Store the current video track


    useEffect(() => {
        window.addEventListener("resize", drawOverlay);

        return () => {
            window.removeEventListener("resize", drawOverlay);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!isProcessingRef.current) {
                await processFrame(); // Call processFrame only if not already processing
            }
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []); // Run only once after the component mounts

    // Fetch available video devices
    useEffect(() => {
        const fetchDevices = async () => {
            try {
                // Request camera permissions by accessing the video stream
                await navigator.mediaDevices.getUserMedia({ video: true });

                // Enumerate available devices
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoInputs = devices.filter((device) => device.kind === "videoinput");
                setVideoDevices(videoInputs);

                // Select environment camera if available, otherwise the first camera
                if (videoInputs.length > 0) {
                    const environmentCamera = videoInputs.find((device) =>
                        device.label.toLowerCase().includes("back") || device.facingMode == "environment"
                    );
                    setSelectedDeviceId(environmentCamera ? environmentCamera.deviceId : videoInputs[0].deviceId);
                } else {
                    setErrorMessage("No video input devices found.");
                }
            } catch (error) {
                // Handle permission or device errors
                if (error.name === "NotAllowedError") {
                    setErrorMessage("Camera access denied. Please enable permissions in your browser settings.");
                } else if (error.name === "NotFoundError") {
                    setErrorMessage("No cameras found on this device.");
                } else {
                    console.error("Error accessing cameras:", error);
                    setErrorMessage("Unable to access the camera. Please check your device and permissions.");
                }
            }
        };

        fetchDevices();
    }, []);


    // Start camera when a device is selected
    useEffect(() => {
        const startCamera = async () => {
            try {
                if (!selectedDeviceId) return;

                const stream = await navigator.mediaDevices.getUserMedia({
                    video: { deviceId: { exact: selectedDeviceId } },
                });

                const videoTrack = stream.getVideoTracks()[0];
                setTrack(videoTrack); // Save the track for light control
                const capabilities = videoTrack.getCapabilities();
                setIsTorchAvailable(!!capabilities.torch);

                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.setAttribute('autoplay', '');
                        videoRef.current.setAttribute('muted', '');
                        videoRef.current.setAttribute('playsinline', '')
                        videoRef.current.play();
                        isCameraReady.current = true; // Set camera readiness
                        drawOverlay();
                    };
                }
            } catch (error) {
                console.error("Failed to access the camera:", error);
                setErrorMessage("Failed to access the camera.");
            }
        };

        startCamera();

        return () => {
            if (videoRef.current?.srcObject) {
                videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            }
        };
    }, [selectedDeviceId]);

    const handleOnClose = () => {
        stopScanning();
        onClose();
    };
    const showScreenCap = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        // Toggle display between "block" and "none"
        if (canvas.style.display === "none") {
            canvas.style.display = "block";
        } else {
            canvas.style.display = "none";
        }
    };

    const stopScanning = () => {
        // Stop the video stream
        if (videoRef.current?.srcObject) {
            videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        }

        // Clear the overlay canvas
        const overlayCanvas = overlayCanvasRef.current;
        if (overlayCanvas) {
            const ctx = overlayCanvas.getContext("2d");
            ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
        }
    };

    const toggleLight = async () => {
        if (!track) return;

        try {
            const capabilities = track.getCapabilities();
            if (capabilities.torch) {
                await track.applyConstraints({
                    advanced: [{ torch: !isLightOn }],
                });
                setIsLightOn((prev) => !prev); // Toggle the state
            } else {
                setErrorMessage("Torch/Light is not supported on this device.");
            }
        } catch (error) {
            console.error("Error toggling light:", error);
            setErrorMessage("Failed to toggle the light.");
        }
    };

    const drawOverlay = () => {
        const video = videoRef.current;
        const overlayCanvas = overlayCanvasRef.current;

        if (!video || !overlayCanvas || !video.videoWidth || !video.videoHeight) return;

        // Get the exact display size of the video element
        const videoDisplayWidth = video.clientWidth;
        const videoDisplayHeight = video.clientHeight;

        // Match the overlay canvas dimensions to the video display size
        overlayCanvas.width = videoDisplayWidth;
        overlayCanvas.height = videoDisplayHeight;

        const ctx = overlayCanvas.getContext("2d");
        ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

        // Calculate the rectangle's position and dimensions in display size
        const rectWidth = videoDisplayWidth * 0.8; // 80% of video display width
        const rectHeight = videoDisplayHeight * 0.2; // Adjust fixed height proportionally
        const rectX = (videoDisplayWidth - rectWidth) / 2;
        const rectY = (videoDisplayHeight - rectHeight) / 2;

        // Draw the rectangle on the overlay canvas
        ctx.strokeStyle = "red";
        ctx.lineWidth = 4;
        ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);
    };

    const captureFrame = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;

        if (!canvas || !video) return;
        // Ensure video has valid dimensions
        if (video.videoWidth === 0 || video.videoHeight === 0) return null;

        // Set canvas dimensions to match the video's native resolution
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext("2d");

        // Draw the current video frame on the canvas
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Get the scaling factors between display size and native resolution
        const videoDisplayWidth = video.clientWidth;
        const videoDisplayHeight = video.clientHeight;
        const scaleX = video.videoWidth / videoDisplayWidth;
        const scaleY = video.videoHeight / videoDisplayHeight;

        // Calculate the cropping rectangle in display space
        const rectDisplayWidth = videoDisplayWidth * 0.8; // 80% of video display width
        const rectDisplayHeight = videoDisplayHeight * 0.2; // 20% of video display height
        const rectDisplayX = (videoDisplayWidth - rectDisplayWidth) / 2; // Center horizontally
        const rectDisplayY = (videoDisplayHeight - rectDisplayHeight) / 2; // Center vertically

        // Scale the rectangle to native resolution
        const rectNativeX = rectDisplayX * scaleX;
        const rectNativeY = rectDisplayY * scaleY;
        const rectNativeWidth = rectDisplayWidth * scaleX;
        const rectNativeHeight = rectDisplayHeight * scaleY;

        ctx.strokeStyle = "green";
        ctx.lineWidth = 4;
        ctx.strokeRect(rectNativeX, rectNativeY, rectNativeWidth, rectNativeHeight);

        // Create a cropped canvas
        const croppedCanvas = document.createElement("canvas");
        croppedCanvas.width = rectNativeWidth;
        croppedCanvas.height = rectNativeHeight;

        const croppedCtx = croppedCanvas.getContext("2d");

        // Crop the video based on the scaled rectangle
        croppedCtx.drawImage(
            canvas,
            rectNativeX,
            rectNativeY,
            rectNativeWidth,
            rectNativeHeight,
            0,
            0,
            rectNativeWidth,
            rectNativeHeight
        );

        // Convert the cropped canvas to a binary Blob
        return new Promise((resolve) => {
            croppedCanvas.toBlob((blob) => {
                resolve(blob);
            }, "image/png");
        });
    };

    const processFrame = async () => {
        if (isProcessingRef.current || !isCameraReady.current) {
            return; // Skip processing if camera is not ready
        }


        isProcessingRef.current = true; // Set ref to true immediately

        const frame = await captureFrame();
        if (!frame) {
            setErrorMessage("Failed to capture frame.");
            isProcessingRef.current = false;
            return;
        }

        try {
            //const { data: { words } } = await workerRef.current.recognize(frame);
            //hit api here
            var response = await scanVinImage(frame);

            //if success
            if (response.success) {
                onVinDetected(response.vin);
                handleOnClose();
            }

        } catch (err) {
            console.error("OCR Error:", err);
            setErrorMessage("Error processing the image. Please try again.");
        }
        finally {
            isProcessingRef.current = false; // Reset ref after processing
        }
    };

    const handleDeviceChange = (event) => {
        setSelectedDeviceId(event.target.value);
    };

    return (
        <>
            <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 8888,
                backgroundColor: "white"
            }}>

                <div
                    style={{
                        position: "absolute",
                        top: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 1000,
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <label
                        htmlFor="cameraSelector"
                        style={{ fontSize: "14px", marginRight: "10px", fontWeight: "bold" }}
                    >
                        Select Camera:
                    </label>
                    <select
                        id="cameraSelector"
                        style={{
                            padding: "6px 12px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            outline: "none",
                        }}
                        onChange={handleDeviceChange}
                        value={selectedDeviceId || ""}
                    >
                        {videoDevices.map((device) => (
                            <option key={device.deviceId} value={device.deviceId}>
                                {selectedDeviceId === device.deviceId ? "● " : "○ "}
                                {device.label || `Camera ${device.deviceId}`}
                            </option>
                        ))}
                    </select>
                </div>
                {/* Video */}
                <video
                    ref={videoRef}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                ></video>
                {/* canvas for captureing frames */}
                <canvas ref={canvasRef}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: "none",
                        zIndex: 250
                    }}></canvas>
                {/* Overlay */}
                <canvas
                    ref={overlayCanvasRef}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        zIndex: 9999
                    }}
                ></canvas>
                <div style={{ position: "absolute", bottom: "10%", width: "100%", textAlign: "center", zIndex: 1000 }}>
                    <div className="btn btn-danger" onClick={handleOnClose}>Close</div>
                    <div
                        className={`ml-3 btn btn-warning ${!isTorchAvailable ? "d-none" : ""}`}
                        onClick={isTorchAvailable ? toggleLight : undefined} // Prevent onClick if the button is disabled
                    >
                        {isLightOn ? "Turn Off Light" : "Turn On Light"}
                    </div>
                </div>
                {errorMessage && <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>}
            </div>
        </>
    );
};

export default Scanner;
