import React, { useState } from "react";
import Scanner from "./Scanner";

const VinScan = ({ onVinDetected }) => {
    const [isScannerOpen, setIsScannerOpen] = useState(false);

    const handleStartScan = () => {
        setIsScannerOpen(true);
    };

    const handleCloseScanner = () => {
        setIsScannerOpen(false);
    };

    return (
        <div className="col">
            {!isScannerOpen ? (
                <div className="btn btn-primary w-100" onClick={handleStartScan}>
                        <span
                        className="material-symbols-outlined align-middle pb-1"
                        style={{ fontSize: "20px", lineHeight: "1" }} // Adjust size and alignment
                    >filter_center_focus</span>&nbsp;Scan a VIN
                </div>
            ) : (
                <Scanner onVinDetected={onVinDetected} onClose={handleCloseScanner} />
            )}
        </div>
    );
};

export default VinScan;
