const VdrHistoryItem = ({ index, vdr }) => {
    if (1 == 2) { }
    return (
        <>
            <div key={vdr.VehicleDamageReportId}>
                <div className="card mt-1 shadow mt-2 pt-2">
                    <div className="row">
                        <div className="col p-0 text-center ms-2">
                            <div
                                className={`btn ${vdr.DamageDetails.length > 0
                                    ? "btn-danger"
                                    : "btn-success"
                                    } position-relative`}
                            >
                                <span className="material-symbols-outlined">
                                    minor_crash
                                </span>
                                <span
                                    className={`position-absolute top-0 start-100 translate-middle badge rounded-pill 
                                    ${vdr.DamageDetails.length > 0 ? "bg-danger" : "bg-success"}`}
                                >
                                    {vdr.DamageDetails.length}
                                </span>
                            </div>
                            <div
                                className={`m-3 btn text-w ${vdr.VdrSources.RecallCheckId == null || !vdr.VdrSources.RecallStatus ? "btn-warning"
                                    : (vdr.RecallDetails.length > 0
                                        ? "btn-danger"
                                        : "btn-success")
                                    } position-relative`}
                            >
                                {vdr.VdrSources.RecallStatus ?
                                    (<span className="material-symbols-outlined">
                                        campaign
                                    </span>) :
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }
                                {vdr.VdrSources.RecallCheckId != null && vdr.VdrSources.RecallStatus ?
                                    (
                                        <span
                                            className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${vdr.RecallDetails.length > 0
                                                ? "bg-danger"
                                                : "bg-success"
                                                }`}
                                        >
                                            {vdr.RecallDetails.length}
                                        </span>
                                    ) : <></>}
                            </div>
                        </div>
                        <div className="col text-start me-2">
                            <div className="fw-bold">{`${vdr.VinDecodeInfo.Year} ${vdr.VinDecodeInfo.Make} ${vdr.VinDecodeInfo.Model} ${vdr.VinDecodeInfo.Trim}`}</div>
                            <div className="fw-bold">{vdr.Vin}</div>
                            <div className="row">
                                <div className="col">
                                    <span>{vdr.UserName} </span>
                                    <span>
                                        {"Date: "}
                                        {vdr.LookupDate
                                            ? new Date(vdr.LookupDate).toLocaleString(undefined, {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                            })
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col w-100">
                            <a
                                href={`/Vdr/${vdr.VehicleDamageReportId}`}
                                className="btn btn-primary w-100 rounded-top-0"
                                rel="noopener noreferrer"
                            >
                                View VDR&nbsp;
                                {/*<span className="align-middle material-symbols-outlined">*/}
                                {/*    open_in_new*/}
                                {/*</span>*/}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VdrHistoryItem;