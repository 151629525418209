import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import VdrHistory from "./Pages/VdrHistory/VdrHistory"
import VdrOrder from './Pages/VdrOrder/VdrOrder';
import Vdr from './Pages/Vdr/Vdr';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
                <Layout>
                    <Routes>
                        <Route path="/" element={<VdrOrder />} />
                        <Route path="/VdrHistory" element={<VdrHistory />} />
                        <Route path="/VdrOrder" element={<VdrOrder />} />
                        <Route path="/Vdr/:id" element={<Vdr />} />
                    </Routes>
                </Layout>
        );
    }
}
