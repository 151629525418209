import { msalInstance } from "../index"; // Assuming msalInstance is initialized in your index.js
import { msalConfig } from "../Authentication/authConfig";
import { addClaimsToStorage, getClaimsFromStorage } from "../Authentication/storageUtils";
import { parseChallenges } from "../Authentication/claimsUtils";
import { BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser"; // Add this import to resolve the BrowserAuthError issue

// Function to acquire a token silently, with fallback to popup or redirect if necessary
export const getToken = async (endpoint, scopes, method) => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw new Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    // Prepare the token request
    const tokenRequest = {
        account: account,
        scopes: scopes,
        redirectUri: msalConfig.auth.redirectUri,  // ensure the redirect URI matches your registered app
        claims: getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${new URL(endpoint).hostname}.${method}`) ?
            window.atob(getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${new URL(endpoint).hostname}.${method}`)) : null
    };

    try {
        // Try to acquire the token silently
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;

    } catch (error) {
        console.error("Silent token acquisition failed, attempting interactive methods", error);

        // Handle specific errors or claims challenges
        if (error.name === "InteractionRequiredAuthError" || error.message.includes("interaction_required")) {
            // Try to handle the claim challenge via popup first, fallback to redirect if popups are blocked
            return handleClaimsChallenge(endpoint, tokenRequest, method, error);
        } else {
            // For other errors, throw an exception or handle appropriately
            throw error;
        }
    }
};

// Function to handle claims challenges (fallback to interactive method if silent token acquisition fails)
const handleClaimsChallenge = async (endpoint, tokenRequest, method, error) => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        console.error("No active account found. User might not be signed in.");
        throw new Error("No active account! Sign in is required.");
    }
    // Make sure the response is passed in as an argument if it is needed for claims challenge processing
    const authenticateHeader = error.response?.headers?.get('www-authenticate');
    if (!authenticateHeader) {
        throw new Error("No 'www-authenticate' header found in the response.");
    }

    const claimsChallenge = parseChallenges(authenticateHeader);

    // Store the claims in local storage for subsequent token requests
    addClaimsToStorage(
        `cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${new URL(endpoint).hostname}.${method}`,
        claimsChallenge.claims
    );

    try {
        // Try to acquire token via popup (fallback to redirect if popups are blocked)
        const tokenResponse = await msalInstance.acquireTokenPopup({
            scopes: tokenRequest.scopes,
            claims: window.atob(claimsChallenge.claims), // decode the base64 string
            redirectUri: msalConfig.auth.redirectUri,
        });

        return tokenResponse.accessToken;

    } catch (popupError) {
        if (popupError instanceof BrowserAuthError &&
            (popupError.errorCode === "popup_window_error" || popupError.errorCode === "empty_window_error")) {

            // Fallback to redirect if popups are blocked
            msalInstance.acquireTokenRedirect({
                scopes: tokenRequest.scopes,
                claims: window.atob(claimsChallenge.claims),
                redirectUri: msalConfig.auth.redirectUri,
            });
        } else {
            throw popupError;
        }
    }
};

export const handleResponse = async (response, endpoint, options) => {
    if (response.ok) {
        return await response.json();
    } else {
        console.error(`API call to ${endpoint} failed with status: ${response.status}`);
        throw new Error(`Request failed with status ${response.status}`);
    }
};

