import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getVdrById } from "../../Queries/VdrApi/vdrApi";

const Vdr = () => {
    const { id } = useParams();
    const [vdr, setVdr] = useState(null);
    const [loading, setLoading] = useState(false);

    // UseEffect to fetch data when component mounts or id changes
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await getVdrById(id);
            setVdr(data.value[0]);
            setLoading(false);
        };

        fetchData();
    }, [id]); // Adding id to the dependency array


    const getRecallCard = () => {
        return (
            <div className="card mt-3" >
                <div className={`card-header ${vdr.VdrSources.RecallCheckId == null || !vdr.VdrSources.RecallStatus ? "bg-warning"
                    : (vdr.RecallDetails.length > 0
                        ? "bg-danger"
                        : "bg-success")
                    }`}>
                    <h4>
                        <span className="material-symbols-outlined">campaign</span>
                        Recall Details
                    </h4>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            {vdr.RecallDetails.length > 0 ?
                                (vdr.RecallDetails.map((recallRecord, index) => (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <div>{recallRecord.Title}</div>
                                            </div>
                                        </div>
                                    </>
                                ))
                                )
                                : <div>
                                    {vdr.VdrSources.RecallCheckId == null || !vdr.VdrSources.RecallStatus ? "No Recalls Available for this OEM"
                                        : "No Recalls Found"
                                    }</div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    const getDamageCard = () => {
        return (
            <div className="card mt-3">
                <div className={`card-header ${vdr.DamageDetails.length > 0 ? "bg-danger" : "bg-success"}`}>
                    <h4>
                        <span className="material-symbols-outlined">minor_crash</span>
                        Damage Details
                    </h4>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="row">
                                <div className="col">Date</div>
                                <div className="col">Amount</div>
                                <div className="col">Location</div>
                                {/*<div className="col">POI</div>*/}
                                {/*<div className="col">Desc.</div>*/}
                            </div>
                            {vdr.DamageDetails.sort(
                                (a, b) => new Date(b.DateOfLoss) - new Date(a.DateOfLoss)
                            ).map((damageRecord, index) => (
                                <>
                                    <div className="row">
                                        <div className="col">
                                            {damageRecord.DateOfLoss
                                                ? new Date(
                                                    damageRecord.DateOfLoss
                                                ).toLocaleString([], { dateStyle: "short" })
                                                : ""}
                                        </div>
                                        <div className="col">
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            }).format(damageRecord.GrossTotalAmount)}
                                        </div>
                                        <div className="col">
                                            {damageRecord.StateProv}&nbsp;
                                            {damageRecord.Country}
                                        </div>
                                        {/*<div className="col">*/}
                                        {/*  {damageRecord.PrimaryPointOfImpact}&nbsp;*/}
                                        {/*  {damageRecord.SecondaryPointOfImpact}*/}
                                        {/*</div>*/}
                                        {/*<div className="col">*/}
                                        {/*  {damageRecord.LossDescription}*/}
                                        {/*</div>*/}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : vdr ? (<>
                <div className="card text-center mt-3">
                    <div className="card-body">
                        <div className="card-title">
                            <h4>Vehicle Detiails</h4>
                        </div>
                        <div>
                            <div>{`${vdr.VinDecodeInfo.Year} ${vdr.VinDecodeInfo.Make} ${vdr.VinDecodeInfo.Model} ${vdr.VinDecodeInfo.Trim}`}</div>
                            <div>{vdr.Vin}</div>
                            <div>
                                {vdr.LookupDate
                                    ? new Date(vdr.LookupDate).toLocaleString()
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {getDamageCard()}
                {getRecallCard()}

            </>
            ) : (
                <p>No vehicle damage report found.</p>
            )}
        </div>
    );
};
export default Vdr;
