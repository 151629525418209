import React, { Component } from 'react';
import NavBar from './NavBar'

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <>
                <NavBar />
                <div className="container-fluid" tag="main">
                    {this.props.children}
                </div>
            </>
        );
    }
}
