import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getVinDecode, orderVdr } from "../../Queries/VdrApi/vdrApi";
import VinScan from "../../components/Vin/VinScan";

const VdrOrder = () => {
    const vinScanRef = useRef(null);
    const [vin, setVin] = useState("");
    const [vinDecodes, setVinDecodes] = useState([]);
    const [scanning, setScanning] = useState(false);
    const [isDecoding, setIsDecoding] = useState(false);
    const navigate = useNavigate();

    const stopScanning = () => {
        if (vinScanRef.current) {
            setScanning(false);
            vinScanRef.current.stopScan();
        }
    };

    const handleVinDetected = (detectedVin) => {
        setVin(detectedVin);
        if (detectedVin.length == 17)
            decodeVin();
        stopScanning(); // Stop scanning once a VIN is detected
    };

    const decodeVin = async () => {
        if (!vin || isDecoding) return;

        setIsDecoding(true);
        try {
            const decodeInfo = await getVinDecode(vin);
            setVinDecodes([
                ...vinDecodes,
                { ...decodeInfo, Selected: true, isLoading: false, isOrdered: false },
            ]);
            setVin(""); // Clear VIN input after decoding
        } catch (error) {
            console.error("Error decoding VIN:", error);
            alert("Failed to decode VIN. Please ensure the VIN is valid and try again.");
        } finally {
            setIsDecoding(false);
        }
    };

    const orderReport = async (vin) => {
        // Update the specific VIN's loading state
        setVinDecodes((prevVinDecodes) =>
            prevVinDecodes.map((vinDecode) =>
                vinDecode.vin === vin ? { ...vinDecode, isLoading: true } : vinDecode
            )
        );

        try {
            await orderVdr(vin);
            // Update the specific VIN's ordered state
            setVinDecodes((prevVinDecodes) =>
                prevVinDecodes.map((vinDecode) =>
                    vinDecode.vin === vin
                        ? { ...vinDecode, isLoading: false, isOrdered: true }
                        : vinDecode
                )
            );
        } catch (error) {
            console.error("Error ordering reports:", error);
            alert("Failed to order reports. Please try again.");
            // Reset the loading state in case of error
            setVinDecodes((prevVinDecodes) =>
                prevVinDecodes.map((vinDecode) =>
                    vinDecode.vin === vin ? { ...vinDecode, isLoading: false } : vinDecode
                )
            );
        }
    };

    const viewReports = () => {
        navigate("/vdrhistory");
    };

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-10">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="vin_label">
                            Enter VIN:
                        </span>
                        <input
                            type="text"
                            value={vin}
                            onChange={(e) => setVin(e.target.value)}
                            className="form-control"
                            placeholder="1234567890ABCDEFG"
                            aria-label="VIN"
                            aria-describedby="vin_label"
                        />
                        <button
                            className="btn btn-primary"
                            onClick={decodeVin}
                            disabled={!vin || isDecoding}
                        >
                            {isDecoding ? "Decoding..." : "Decode"}
                        </button>
                    </div>
                    <VinScan ref={vinScanRef} onVinDetected={handleVinDetected} />
                    {vinDecodes.length > 0 && (
                        <>
                            <div className="row">
                                <div className="col text-center">
                                    <h4 className="mt-4">Decoded VINs:</h4>
                                    {vinDecodes.map((vinDecode, index) => (
                                        <div className="card mb-2 shadow" key={index}>
                                            <div className="card-body">
                                                <h5 className="card-title">{`${vinDecode.year} ${vinDecode.make} ${vinDecode.model} ${vinDecode.trim}`}</h5>
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    {vinDecode.vin}
                                                </h6>
                                            </div>
                                            <button
                                                className="btn btn-success rounded-top-0"
                                                onClick={
                                                    vinDecode.isOrdered
                                                        ? viewReports
                                                        : () => orderReport(vinDecode.vin)
                                                }
                                                disabled={vinDecode.isLoading}
                                            >
                                                {vinDecode.isLoading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : vinDecode.isOrdered ? (
                                                    "View Reports"
                                                ) : (
                                                    "Order Report"
                                                )}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VdrOrder;
